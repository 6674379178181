import React from 'react'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'
import AssignmentsReviews from '../../../components/pages/StarTutoring/AssignmentsReviews'

const StarTutoringReviewsPage = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <AssignmentsReviews />
  </StarTutoringLayout>
}

export default StarTutoringReviewsPage
