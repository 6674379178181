import React, { useCallback, useEffect, useMemo, useState } from 'react'
import 'twin.macro'
import { Link, navigate } from 'gatsby';
import styled from 'styled-components';
import { FormattedDate } from 'react-intl';
import { Avatar, Button, Checkmark, IconButton, Refresh } from 'nzk-react-components';
import GET_ASSIGNMENTS_DRAFT from '../../../data/Assignments/graphql/getAssignmentsToReview.graphql'

import Table from '../../../../../TableV2';
import { useQuery } from '@apollo/client';

const Wrapper = styled.div``

const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  > * { margin-right: 10px; }
`

const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

const AssignmentsDraft = () => {
  const queryParams = typeof window !== 'undefined'
  ? new URLSearchParams(window.location.search)
  : null
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [nbPages, setNbPages] = useState(1)
  const [filters,] = useState({ draft: { eq: true } })

  const { data, loading, refetch, fetchMore } = useQuery(GET_ASSIGNMENTS_DRAFT, {
    variables: {
      filters: { draft: { eq: true } },
      skip: pageIndex * pageSize,
      limit: pageSize
    },
    fetchPolicy: queryParams?.get('refetch') === 'true' ? 'network-only' : undefined
  })

  const assignments = data?.starTutoring_assignments || []


  const fetchPage = useCallback(({ skip, limit, refetch }) => {
    setPageIndex(skip / limit)
    setPageSize(limit)

    fetchMore({
      variables: {
        filters: { draft: { eq: true } },
        skip,
        limit,
      }
    })
  }, [filters])

  useEffect(() => {
    setPageSize(pageSize)
  }, [])
  
  useEffect(() => {
    if (assignments.length === pageSize && pageIndex >= nbPages - 1) {
      setNbPages(p => Math.max(pageIndex + 2, p + 1))
    }
  }, [assignments])

  const columns = useMemo(() => {
    return [
      {
        'Header': 'Zookeeper',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <div tw='flex cursor-pointer items-center' onClick={() => {
          navigate(`/star-tutoring/zookeepers/${row.original.zookeeper.username}`)
        }}>
          <Avatar user={{ ...row.original.zookeeper, type: 'student' }} size='45px' />
          <div tw='ml-2'>{row.original.zookeeper.username}</div>
        </div>
      },
      {
        'Header': 'Started At',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <FormattedDate value={row.original.starTutoringReview?.createdAt} />
      },
      {
        'Header': 'Started By',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <div tw='flex cursor-pointer items-center'>
          <Avatar user={row.original.starTutoringReview?.createdBy} size='45px' />
          <div tw='ml-2'>{row.original.starTutoringReview?.createdBy?.name}</div>
        </div>
      },
      {
        Header: 'Writing',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => row.original.writing ? <Actions>
          <Link to={`/writing/${row.original.writing._id}`}>
            {row.original.writing.title}
          </Link>
        </Actions> : 'Not found'
      },
      {
        'Header': 'Actions',
        disableFilters: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => <Actions>
        <Link to={`/star-tutoring/reviews/${row.original._id}`}>
          <IconButton theme='orange' size='x-small' icon={<Checkmark />}>Review</IconButton>
        </Link>
      </Actions>
      }
    ]
  }, [assignments])

  return <Wrapper>
    <Header>
      <IconButton size='x-small' theme='primary' icon={<Refresh />} onClick={() => refetch()}>Refresh</IconButton>
    </Header>
    <Table columns={columns} data={assignments} tableOptions={{
      manualPagination: true,
    }} fetchPage={fetchPage} pageCount={nbPages} loading={loading} />
  </Wrapper>
}

export default AssignmentsDraft
