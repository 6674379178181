import React from 'react'
import styled from 'styled-components';
import Assignments from './components/Assignments';
import AssignmentsDraft from './components/AssignmentsDraft';

const Wrapper = styled.div``

const Section = styled.div``

const Title = styled.h2``

const AssignmentsReview = () => {

  return <Wrapper>
    <Section>
      <Title>Reviews to do</Title>
      <Assignments />
    </Section>
    <Section>
      <Title>Reviews started by tutors</Title>
      <AssignmentsDraft />
    </Section>
  </Wrapper>
}

export default AssignmentsReview
